.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0; 
}

.header {
  background-color: black;
  color: white;
  padding: 20px;
  text-align: center;
}

.h1 {
  margin: 0;
  text-align: center;
  animation: slideInFromLeft 0.2s ease-out;
}

.h2 {
  text-align: center;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-15px);
    opacity: 0.5;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.p {
  text-align: center;
}

.content {
  display: flex;  
  flex-direction: column;  
  
}

.stylish_text {
  line-height: 1.4;
  align-self: flex-start;
  font-size: 2.6rem;
  font-weight: 700;
  color: #1a1a1a;
  text-shadow: 1px 1px 2px rgba(82, 79, 79, 0.5);
}

.fadeEnter {
  opacity: 0;
}

.fadeEnterActive {
  opacity: 1;
  transition: opacity 500ms;
}

.fadeExit {
  opacity: 1;
}

.fadeExitActive {
  opacity: 0;
  transition: opacity 500ms;
}

