@keyframes slideIn {
  0% {
    top: -40px;
    opacity: 0;
  }
  100% {
    top: 10px;
    opacity: 1;
  }
}

@keyframes slideOut {
  0% {
    top: 10px;
    opacity: 1;
  }
  100% {
    top: -40px;
    opacity: 0;
  }
}

.container {
  width: 720px;
  height: calc(100vh - 115px);  
  padding: 20px 1px;
  padding-left: 10px;
  background-image: url("../../assets/img/bg.png");
  background-repeat: repeat;
  background-position: top left;
  overflow-y: scroll;
  scrollbar-color: #fff #bbb;
  padding-bottom: 10px;
  box-shadow: -8px 0 5px rgba(0, 0, 0, 0.25), 8px 0 5px rgba(0, 0, 0, 0.25);
  position: relative;
}

.container::-webkit-scrollbar {
  width: 8px;
}

.container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4);
}

.container::-webkit-scrollbar-track {
  background: transparent;
}

.container::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.chatContainer {
  display: flex;
  align-items: flex-start;
  margin: 10px;
  margin-bottom: 12px;
  position: relative;
  padding: 0 40px;
}

.chatPhoto {
  margin-right: 10px;
}

.leftImg {
  border-radius: 50%;
  width: 35px;
  height: 35px;
  position: absolute;
  top: -19px;
  left: -5px;
  border: 2.5px solid white;
}

.rightImg {
  border-radius: 50%;
  width: 35px;
  height: 35px;
  position: absolute;
  top: -19px;
  right: -5px;
  border: 2.5px solid white;
}

.chatBalloon {
  background: #fff;
  min-width: 100px;
  border-radius: 7px;
  padding: 10px 15px;
  padding-bottom: 20px;
  position: relative;
  max-width: 60%;
  word-wrap: break-word;
  overflow-wrap: break-word;  
}

.chatBalloon p {
  margin: 0;
}

.pointLeft {
  position: absolute;
  top: 0;
  left: -15px;
}

.pointRight {
  position: absolute;
  top: 0;
  right: -15px;
}

.fadeEnter {
  opacity: 0;
  transform: translateX(-50px);
}

.fadeEnterActive {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.fadeExit {
  opacity: 1;
  transform: translateX(0);
}

.fadeExitActive {
  opacity: 0;
  transform: translateX(-50px);
  transition: opacity 300ms, transform 300ms;
}

.fadeEnterMyMessage {
  opacity: 0;
  transform: translateX(50px);
}

.fadeEnterActiveMyMessage {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.fadeExitMyMessage {
  opacity: 1;
  transform: translateX(0);
}

.fadeExitActiveMyMessage {
  opacity: 0;
  transform: translateX(50px);
  transition: opacity 300ms, transform 300ms;
}

.fadeEnter {
  opacity: 0;
  transform: translateY(20px);
}

.fadeEnterActive {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms ease-in, transform 300ms ease-in;
}

.fadeExit {
  opacity: 1;
  transform: translateY(0);
}

.fadeExitActive {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 300ms ease-in, transform 300ms ease-in;
}

.fadeEnterDelayed {
  animation-delay: var(--delay);
}

.closeBtn {
  position: fixed;
  top: 20px; 
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: 50px;
  z-index: 10;
  cursor: pointer;
  transition: opacity 0.5s ease;
}

.container:hover .closeBtn {
  animation: slideIn 0.5s forwards;
}

.container:not(:hover) .closeBtn {
  animation: slideOut 0.5s forwards;
}

.overlay {
  position: fixed;
  width: 704px;
  top: 41px;
  left: calc(50% - 8px);
  transform: translateX(-50%); 
  height: 70px;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.5s ease;
  z-index: 5;
  border-bottom-left-radius: 50% 20%;
  border-bottom-right-radius: 50% 20%;
}

.container:hover .overlay {
  opacity: 1;
}

.container:not(:hover) .overlay {
  opacity: 0;
}
