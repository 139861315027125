.container {
  width:  720px;
  height: 190px;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  padding-top: 10px;
}

.inputContainer {
  border-radius: 5px;
  width: 100%;
  position: relative;
  height: "auto";
}

.title {  
  text-align: center;
  font-size: 15;
  font-weight: 500;
  color: #1a1a1a;  
  text-shadow: 0.5px 0.5px 1.5px #7c7c7c;
}

.data {
  min-width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.data span {
  line-height: normal;
  text-align: center;
  font-size: 2.5rem;
  font-weight: lighter;
  color: #000;
  text-shadow: 1px 1px 1.5px #555;
}

.data p {  
  text-align: center;
  font-size: 1.1rem;
  font-weight: 500;
  color: #000000;
  margin-bottom: 3px;  
  line-height: 1ch;
}

.data small { 
  font-size: 0.8rem;
  text-align: center;
}
