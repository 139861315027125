.messageBox {
  width: 100%;
  border-bottom: 2px solid #e0e0e0;
  height: 70px;
  padding: 5px 10px;
  font-size: 14px;
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.messageBox:hover {
  background-color: #f0f0f0;
}

.bodyCustom {
    border-top: 2px solid #c4c4c4;
    padding-top: 0;
    
  }

.promptBox {    
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
    font-style: italic;    
    padding-left: 1px;
  }

.promptLabel {
  font-weight: 600;
  font-style: normal;
}

.totalBox {
  font-style: normal;
  display: flex;
  align-items: center;
  font-weight: 400;
  margin-top: 7.5px;
}

.promptDate {
  position: absolute;
  right: 3.5px;
  bottom: 3.5px;
  font-weight: 300;
}

.tooltip-container .tooltip-text {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%; 
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }
