@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #F2F3FA !important;
}

.title {
  font-size: 23px;  
  font-weight: 700;
  text-transform: uppercase;
  color: #000; /* Cor preta */
  margin: 0;
  margin-bottom: 10px;
  letter-spacing: 0.05em;
  line-height: 1.2;
  position: relative;
  z-index: 1;
}

.title::before {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  color: rgba(0, 0, 0, 0.7); 
  transform: translate(4px, 4px); 
  filter: blur(2px); 
}

.title::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
  color: rgba(0, 0, 0, 0.7); 
  transform: translate(8px, 8px);
  filter: blur(2px); 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-gray {
  font-size: 11px;
  color: #333 !important;  
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
  padding: 2px;
}

.pgtotal {
  color: #333;    
  font-size: 14px;
  font-weight: 700;
  line-height: 1.2rem;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);  
}

.pgmonth {
  color: #333;    
  font-size: 14px;
  font-weight: 700;
  line-height: 1.2rem;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);  
  justify-content: center;
}

.progressData{
  display: flex;
  align-items: space-between;
  justify-content: space-between;
  width: 100%;  
}

.progressData .col {
  padding: 0;
  text-align: center;
  position: relative; /* Necessário para posicionar o pseudo-elemento */
}

.progressData .col:not(:last-child)::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 1px;
  background-color: #444444;
}

.offcanvas-body-custom {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.5) transparent;
}


.offcanvas-body-custom::-webkit-scrollbar {
  width: 6px;
}

.offcanvas-body-custom::-webkit-scrollbar-track {
  background: transparent;
}

.offcanvas-body-custom::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  border: 2px solid transparent;
}

.offcanvas-body-custom::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.7);
}