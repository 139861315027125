.container {
  width:  720px;
  height: 190px;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  padding-top: 10px;
}

.inputContainer {
  border-radius: 5px;
  width: 100%;
  position: relative;
  height: "auto";
}

.promptBox {
  margin-top: 5px;
  margin-bottom: 20px;
  width: 24%;
  height: 115px;
  display: flex;
  align-items: space-between;
  justify-content: start;
  flex-direction: column;
  padding: 10px;
  padding-top: 35px;
  background: linear-gradient(135deg, #fff, #ffffff);
  border-radius: 4px;
  transition: box-shadow 0.3s ease;
  position: relative;
  line-height: 20px;
  border: 1px solid #7c7c7c60;
  font-size: 14px;
  font-weight: 400;
}

.title {
  align-self: start;
  font-size: 15;
  font-weight: 500;
  color: #1a1a1a;
  text-shadow: 0.5px 0.5px 1.5px #7c7c7c;
}
