.container {
  width:  720px;
  min-height: auto;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 5px;
  background: linear-gradient(51deg, #0079bf, #000);
  border-radius: 4px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4); 
}

.inputContainer {  
  width: 100%;
  display:flex;
  position: relative;
  height: "auto";
}

