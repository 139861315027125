.header {
  background-color: black;
  color:white;
  padding: 20px;
  text-align: center;
}


.h1 {
  margin: 0;
  text-align: center;
  animation: slideInFromLeft .2s ease-out;
}

.h2 { 
  text-align: center;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-15px);
    opacity: .5;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.p {
  text-align: center;
}


.footer {
  text-align: center;
  margin-top: 40px;
  color: #0079bf;
  padding: 20px 0;
  background-color: #f4f4f9;
}