.header {
    background-color: black;
    padding: 20px;
    text-align: center;
    color: white;
  }

  .header h1 {
    margin: 0;
    animation: slideInFromLeft .2s ease-out;
  }

  @keyframes slideInFromLeft {
    0% {
      transform: translateX(-15px);
      opacity: .5;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  .description {
    text-align: center;
    max-width: 600px;
    margin: 20px auto;
  }

  .plan_container {
    display: flex;    
    gap: 15px;
    margin-top: 20px;
    flex-wrap: wrap;
    width: 870px;
  }

  .plan {
    border: 1px solid #0079bf;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    width: 275px;
    background-color: white;
    height: 320px;
    display:flex;
    flex-direction: column;
    align-items: center;
  }

  .plan h2 {   
    margin: 0 0 10px;
    color: #0079bf;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: start;
    align-items: center;
    white-space: nowrap;
  }

  .plan p {
    margin: 10px 0;
    color: #333;
  }

  .span_sub {
    margin: 10px 0;
    color:#333;    
    position: relative;
  }

  .paypal_button_container {
    margin-top: 15px;
    min-height: 105px;
    max-width: 205px;
  }

  .footer {
    text-align: center;
    margin-top: 40px;
    color: #0079bf;
    padding: 20px 0;
    background-color: #f4f4f9;
  }

  .faq_section {
    width: 40%;
    min-width: 800px;
    max-width: 800px;
    margin: 40px auto;
  }

  .faq_header {
    text-align: center;
    margin-bottom: 20px;
    color: #0079bf;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .accordion-button::after {
    background-image: none;
  }

  .accordion-button:not(.collapsed) {
    color: #0079bf;
    background-color: #e9ecef;
  }

  .faq_item {
    margin-top: 0;
    color: #0079bf;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }

  .faq_item p {
    color: #333;
    display: none;
    padding: 19px;
  }

  .faq_item p.show {
    display: block;
  }

  .btn-download {
    display: inline-block;
    background: #0079bf;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    margin-top: 20px;
    transition: background 0.3s;
    text-align: center;
  }

  .btn-download:hover {
    background: #005f8f;
    color: white;
  }